/****************************************************************
* Theme Defaults
*****************************************************************/

$primary-color: #c420c9;
$divider-color: #eeeeee;
$divider-color-dark: #aaa;
$white: #ffffff;
$black: #000000;

// Text color map. map-get($map, $key)
$text-color-map: (
  "header": #1d1c1f,
  "title": #39363d,
  "emphasis": #8d8992,
  "emphasis-secondary": #1d1c1f,
  "body-light": #78727d,
  "body-dark": #463f4d,
  "subtle": #bfbbc3,
  "link": $primary-color
);
@mixin body-font {
	font: 400 1.6rem 'Nunito Sans', "Helvetica Neue", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}


$section-spacing: 5rem;