/****************************************************************
* Parallax Settings
*****************************************************************/
$parallax_image_path: '../images/night_island/';   // Where the parallax images reside
$parallax_image_prefix: 'layer';              // File prefix for parallax images. e.g. All files are named as "layer0", "layer1"
$parallax_layers: 7;                          // Number of parallax layer images, names should be in the form
$parallax_background_colour: #3b415b;         // Background colour of the parallax area (important if height != layer image heights) unicorn: #a56d9d night island: #3b415b
$should_generate_2x_classes: false;           // Whether or not you've provided @2x images for your layers

/****************************************************************
* Disabling Parallax
* 
* Disable parallax on screens smaller than 600px.
*****************************************************************/
#nonparallax {
  display: block;
}

#nonparallax {
  background-image: url($parallax_image_path + 'still.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

/****************************************************************
* Defining Parallax
*
* The height of parallax (.keyart, .keyart_layer) area should be 
* the height of the resource images, or the background layer must 
* have a colour fading into a background colour to be seamless.
*****************************************************************/
#parallax {
  display: none;
}

.keyart, .keyart_layer {
  height: 100rem; 
}

.keyart {
  position: relative;
  z-index: 10;
}

.keyart_layer {
  background-position: bottom center;
  background-size: auto 1038px;
  background-repeat: repeat-x;
  width: 100%;
  position: absolute;
}

.keyart_layer.parallax {
  position: fixed;
}

/* Define the background images for each key layer */
@for $i from 0 through $parallax_layers {
  #keyart-#{$i} {
    background-image: url($parallax_image_path + $parallax_image_prefix + '#{$i}.png');
    @if $i == 0 { // Only layer 0 needs a background transition colour
      background-color: $parallax_background_colour;
    }
  }
}

/* Generate classes for high-resolution 2x layer images if desired */
@if $should_generate_2x_classes {
  @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
    @for $i from 0 through $parallax_layers {
      #keyart-#{$i} {
        background-image: url($parallax_image_path + $parallax_image_prefix + '#{$i}@2x.png');
        @if $i == 0 { // Only layer 0 needs a background transition colour
          background-color: $parallax_background_colour;
        }
      }
    }
  }
}

/****************************************************************
* Show and hide #parallax (screen > 600px) and #nonparallax 
* (screen <= 600 px) depending on screen size.
*****************************************************************/
@media (min-width: 601px) {
  #nonparallax {
    display: none;
  }
  #parallax {
    display: block;
  }
}

@media (max-width: 600px) {
  #nonparallax {
    display: block;
  }
  #parallax {
    display: none;
  }
}

/****************************************************************
* Shrink parallax area for very small screens.
*****************************************************************/
@media only screen and (max-device-width: 450px) {
  .keyart, .keyart_layer {
    height: 550px;
  }

  .keyart_layer {
    position: absolute;
    background-size: auto 600px;
  }
}

/****************************************************************
* Make sure the content below the parallax area is visible.
*****************************************************************/
#parallax_main, footer {
  background-color: #fff;
  position: relative;
  z-index: 100; /* Overtop of parallax */
}