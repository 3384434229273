// 6. Collapsible (from _variables)
// ==========================================================================

$collapsible-height: 3rem !default;
$collapsible-line-height: $collapsible-height !default;
$collapsible-header-color: #fff !default;
$collapsible-border-color: #ddd !default;

// 12. Grid (from _variables)
// ==========================================================================

$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width*2)/3 !default;

// (from _global)
// ==========================================================================

.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),
              0 3px 1px -2px rgba(0,0,0,0.12),
              0 1px 5px 0 rgba(0,0,0,0.2);
}

ul {
  &:not(.browser-default) {
    padding-left: 0;
    list-style-type: none;

    & > li {
      list-style-type: none;
    }
  }
}

// _collapsible pared down
// ==========================================================================

.collapsible {
  border-top: 1px solid $collapsible-border-color;
  border-right: 1px solid $collapsible-border-color;
  border-left: 1px solid $collapsible-border-color;
  margin: $element-top-margin 0 $element-bottom-margin 0;
  // @extend .z-depth-1;
}

.collapsible-header {
  &:focus {
    outline: 0
  }

  display: flex;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.5;
  padding: 1rem;
  background-color: $collapsible-header-color;
  border-bottom: 1px solid $collapsible-border-color;

  i {
    width: 2rem;
    font-size: 1.6rem;
    display: inline-block;
    text-align: center;
    margin-right: 1rem;
  }
}
.keyboard-focused .collapsible-header:focus {
  background-color: #eee;
}

.collapsible-body {
  display: none;
  border-bottom: 1px solid $collapsible-border-color;
  box-sizing: border-box;
  padding: 2rem;
  background-color: #eee;
}

// Sidenav collapsible styling
.sidenav,
.sidenav.fixed {

  .collapsible {
    border: none;
    box-shadow: none;

    li { padding: 0; }
  }

  .collapsible-header {
    background-color: transparent;
    border: none;
    line-height: inherit;
    height: inherit;
    padding: 0 0;

    &:hover { background-color: rgba(0,0,0,.05); }
    i { line-height: inherit; }
  }

  .collapsible-body {
    border: 0;
    background-color: $collapsible-header-color;

    li a {
      padding: 0 (7.5px)
               0 (15px);
    }
  }
}
