/****************************************************************
* Set REM to an apparent value of 10px.
* More info: https://stackoverflow.com/a/43131958
*****************************************************************/
:root {
	font-size: 62.5%;
}
body {
	font: 400 1.6rem 'Nunito Sans', "Helvetica Neue", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 1.6rem;
	line-height: 2.4rem;
}


h1, h2, h3, h4 {
color: map_get($text-color-map, "title");
}
// Cool trick for scaling text (with min and max)
// body {
//   font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
//   line-height: calc(1.3em + (1.5 - 1.2) * ((100vw - 300px)/(1600 - 300)));
// }

.center {
	display: block;
	margin: 0 auto;
}

/****************************************************************
* General Defaults
*****************************************************************/
a:hover, a:visited, a:link, a:active {
    text-decoration: none;
    color: map_get($text-color-map, "link");
}

p.button {
	font-size: 1.7rem;
	text-align: center;
	text-decoration: none;

	a {
		background-color: $primary-color;
		-moz-border-radius: 0.7rem;;
		-webkit-border-radius: 0.7rem;;
		border-radius: 0.7rem;
		display: inline-block;
		margin: 0 auto;
		cursor: pointer;
		color: white;		
		padding: 1.5rem 3.1rem;		
	}
}

/****************************************************************
* Aspect Ratio Boxes
* https://css-tricks.com/aspect-ratio-boxes/
*****************************************************************/
[style*="--aspect-ratio"] > :first-child {
	width: 100%;
  }
  [style*="--aspect-ratio"] > img {  
	height: auto;
  } 
  @supports (--custom:property) {
	[style*="--aspect-ratio"] {
	  position: relative;
	}
	[style*="--aspect-ratio"]::before {
	  content: "";
	  display: block;
	  padding-bottom: calc(100% / (var(--aspect-ratio)));
	}  
	[style*="--aspect-ratio"] > :first-child {
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 100%;
	}  
  }

/****************************************************************
* Header
*****************************************************************/
$header_height: 5rem;
$header-image-size: 3rem;
header {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
	height: $header_height;
	border-bottom: 1px solid $divider-color;
	background-color: rgba(255, 255, 255, 0.95);

	.site-title, .wrapper {
		height: $header_height;
	}

	.site-title {
		font-size: 2.6rem;
		font-weight: 400;
		color: $primary-color;
		line-height: $header_height;
		margin-right: 1rem;
	}

	.header-image {
		width: $header-image-size;
		height: $header-image-size;
		display: inline;
		line-height: $header_height;
		border-radius: 1.5rem;
		margin: ($header_height - $header-image-size)/2 0;
	}

	.site-nav {
		font-size: 1.6rem;
		line-height: $header_height;
	}
	
	.site-nav a:hover {
		text-decoration: none;
		color: $primary-color;
	}	

	@media (max-width: 600px) {
		.site-nav {
			.page-link {
				padding: 1.5rem 1rem;
				margin-left: 3rem;
				margin-right: 1rem;
				font-size: 2.5rem;
			}

			.page-link:not(:last-child) {
				margin-right: 1rem; 
			} 
		}
	}
}

.page-content {
	padding: $header_height + $section-spacing 0;
}

#parallax_main .page-content {
	padding: $header_height 0;
}

/****************************************************************
* Page Body
*****************************************************************/
.page-content {
	div.section {
		padding-bottom: $section-spacing;
		border-bottom: 1px solid $divider-color;
		margin-bottom: $section-spacing;
	}

	h1.section-title {
		margin-bottom: $section-spacing;
		font-size: 5rem;
		text-align: center;
	}

	p.section-description {
		text-align: center;
		color: map-get($text-color-map, "body");
	}

	hr {
		width: 20%;
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid $divider-color-dark;
		margin: $section-spacing * 2 auto;
		padding: 0; 
	}
}

/****************************************************************
* Footer
*****************************************************************/
.site-footer {
	border-top: 1px solid $divider-color;
	padding: 1rem;

	p {
		text-align: center;
		font-size: 1rem;
		margin: 0 1rem;
		color: map-get($text-color-map, "body");
	}
}

/****************************************************************
* Home
*****************************************************************/
.home {
	.home-featured-book {	
		img.book-cover {
			max-width: 60%;
			display: block;
			margin: 0 auto;
			margin-bottom: 2rem;
		}

		h2 {
			text-align: center;
			font-size: 3rem;
			font-weight: 700;
			color: map-get($text-color-map, "title");

			a {
				color: map-get($text-color-map, "title");
			}
		}

		p {
			text-align: center;
		}

		.book-info {
			a {
				color: map-get($text-color-map, "title");
			}
		}

		.explore-more {
			margin-top: $section-spacing;
		}
	}

	.blog-posts {
		margin-bottom: $section-spacing;

		.rss-subscribe {
			text-align: center;
			color: map-get($text-color-map, "emphasis");
		}
	}
	
	.author-page-wrapper {
		max-width: 100%;
		padding: $section-spacing 3rem;
		border-radius: 1rem;
		border: 1px solid $divider-color;
		background-color: #fbfafa;
		margin-bottom: $section-spacing;

		.author-header {
			justify-content: center;
		}	
	}
	
	.newsletter {
		border-radius: 1rem;
		border: 1px solid $divider-color;
		margin-bottom: $section-spacing;
		padding: 3rem;
		background-color: #fbfafa;

		h3 {
			margin-bottom: 3rem;
		}

		li:last-child {
			margin-bottom: $section-spacing;
		}
	}

	.related-books {
		margin-bottom: $section-spacing;
	}
}



/****************************************************************
* Blog Post Previews
*****************************************************************/
.blog-preview {
	margin-bottom: 6rem;

    .post-meta {
		font-size: 1.28rem;
		margin-bottom: 0.8rem;
		text-transform: uppercase;

		.post-date {
			margin-bottom: 0.5rem;
			color: map-get($text-color-map, "emphasis");
			font-weight: 600;
		}

		.post-category {
			font-weight: 600;
			margin-left: 0.5rem;
			color: map-get($text-color-map, "emphasis-secondary");
		}

		.post-category {
			text-decoration: none;
			color: map-get($text-color-map, "emphasis-secondary");
			font-weight: 500;
		}
	}

	.post-title {
		font-size: 4rem;
		font-weight: 700;
		color: map-get($text-color-map, "title");
		margin-bottom: 0.8rem;
		letter-spacing: 0.04rem;
	}

	.post-excerpt {
		font-size: 1.6rem;
		color: map-get($text-color-map, "body-light");
		line-height: 2.4rem;
		margin-bottom: 3rem;
	}

	.post-read-more {
		color: $primary-color;
		font-size: 1.6rem;
		border: 1px solid $primary-color;
		padding: 0.5rem 1rem;
	}
}

/****************************************************************
* Blog Post Full
*****************************************************************/
.single-post {

	li {
		margin-bottom: 1.5rem;		
	}
	
	.feature-image {		
		margin-bottom: $section-spacing;

		img {
			display: block;
			margin: 0 auto;
			object-fit: cover;
		}
	}

	.post-title {
		font-size: 5.6rem;
		font-weight: 700;
		color: map-get($text-color-map, "title");
		margin-bottom: 0.8rem;
		letter-spacing: 0.04rem;
	}

    .post-meta {
		-webkit-box-align: start;
		align-items: flex-start;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		margin-bottom: 3rem;
		margin-top: 1rem;

		.post-meta-label {
			color: map-get($text-color-map, "emphasis");
			display: block;
			font-size: 0.96rem;
			font-weight: 500;
			letter-spacing: 0.1em;
			margin-bottom: 1rem;
			text-transform: uppercase;
		}

		.author {
			display: block;
			margin-right: 16px;
			margin-right: 1rem;

			.author-wrapper {
				-webkit-box-align: center;
				align-items: center;
				display: flex;

				.author-avatar {
					height: 3.6rem;
					width: 3.6rem;
					border-radius: 1.8rem;
					overflow: hidden;
					background-color: $divider-color;
				}

				img {
					max-width: 100%;				
				}

				.author-info {
					margin-left: 1rem;

					span {
						display: block;
					}

					.author-name {
						font-size: 1.6rem;
						font-weight: 600;
						line-height: normal;
					}

					.author-title {
						font-size: 1.28rem;
						letter-spacing: 0.02em;
					}
				}
			}		
		}

		.post-info {
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			flex-direction: column;
			line-height: normal;
			color: map_get($text-color-map, "emphasis-secondary");			

			.post-date {
				font-size: 1.6rem;
				font-weight: 600;
				line-height: normal;
			}

			.post-category {
				letter-spacing: 0.02em;
			}
		}
	}

	.md-content {
		color: map-get($text-color-map, "body-dark");

		div.blog-image {
			
			margin-bottom: 1.5rem;
		}
	}
}

/* Related posts can show up on other pages (e.g. author page, single post page, etc.)*/
.related-posts {
	$post-height: 15rem;
	$post-width: 20rem;
	$post-spacing: 1rem;

	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	align-items: stretch;

	h3 {
		font-weight: 200;
	}

	p {
		max-width: $post-width;
	}

	.related-post-detail {
		flex-grow: 0;
		padding: $post-spacing;
		margin: $post-spacing;
		border: 1px solid $divider-color;
		min-width: $post-width;
	}

	div.feature-image {
		width: 100%;
		height: $post-height;
		overflow: hidden;
		background-size: cover;
		background-position: center;
		margin-bottom: $post-spacing;			
	}

	div.feature-image-placeholder {
		width: 100%;
		height: $post-height;
		margin-bottom: $post-spacing;

		display: flex;
		align-items: center;

		p {
			text-align: center;
			width: 100%;
			color: white;
			font-size: 1.5rem;
			font-weight: 700;

		}

		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f6bfe8+0,bf8fb2+24,7a3980+50,301c59+72,0c0717+100 */
		background: #f6bfe8; /* Old browsers */
		background: -moz-linear-gradient(-45deg, #f6bfe8 0%, #bf8fb2 24%, #7a3980 50%, #301c59 72%, #0c0717 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(-45deg, #f6bfe8 0%,#bf8fb2 24%,#7a3980 50%,#301c59 72%,#0c0717 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(135deg, #f6bfe8 0%,#bf8fb2 24%,#7a3980 50%,#301c59 72%,#0c0717 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6bfe8', endColorstr='#0c0717',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	}

	/* Grow related posts to be full width on small screens */
	@media (max-width: 600px) {			
		.related-post-detail {
			width: 100%
		}

		p {
			max-width: 100%;
		}
	}
}

p.photo-credit {
	text-transform: italics;
	font-size: 1.2rem;
	margin-top: $section-spacing;
	text-align: right;
}

/****************************************************************
* Blog Pagination
*****************************************************************/
.pagination a, .pagination span {
	padding: 7px 18px;
	border: 1px solid #eee;
	margin-left: -2px;
	margin-right: -2px;
	background-color: #ffffff;
	display: inline-block;
}

.pagination a {    
	&:hover {
		background-color: #f1f1f1;
		color: #333;
	}
}

.pagination {
	text-align: center;
}

/****************************************************************
* Author Page
*****************************************************************/
.author-page-wrapper {
	max-width: -webkit-calc(600px - (30px * 2));
	max-width: calc(600px - (30px * 2));
	margin-right: auto;
	margin-left: auto;

	@media screen and (max-width: 800px) {
		.author-page-wrapper {
			max-width: -webkit-calc(600px - (30px));
			max-width: calc(600px - (30px));
		}
	}

	.centered-quote {
		text-align: center;
		font-style: italic;
		margin-bottom: $section-spacing / 2;
		text-indent: 0;
		color: map_get($text-color-map, "emphasis");

		p {
			margin-bottom: $section-spacing / 2;
		}
	}

	.author-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: $section-spacing;

		.author-avatar {
			flex-shrink: 0;
			height: 10rem;
			width: 10rem;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			overflow: hidden;
			background-color: $divider-color;

			img {	
				max-width: 100%;				
			}
		}

		.author-info {
			margin-left: 1rem;
			display: flex;
			flex-direction: column;

			.author-name {
				font-weight: 600;
				margin-bottom: 0;
			}

			.author-title {
				margin-left: 0.2rem;
				margin-bottom: 0;
			}
		}
	}

	.md-content {
		margin-bottom: $section-spacing;
		text-indent: 2em;
		color: map-get($text-color-map, "body-dark");
	}

	.book-link {
		text-align: right;
	}
}		

/****************************************************************
* Book Page
*****************************************************************/
.book-page {
	.featured-meta {
		width: 100%;
		display: flex;
		
		@media (max-width: 600px) {	
			flex-wrap: wrap;
			justify-content: center;
		}

		.cover-image {
			max-width: 30%;
			width: 30%;	
			margin: 0 2rem $section-spacing 2rem;
			
			img {
				// object-fit: contain;
				max-width: 100%;
				max-height: 100%;
				width: auto;
				height: auto;
				display: block;
				margin: 0 auto;				
			}

			/* Grow to be full width on small screens */
			@media (max-width: 600px) {			
				max-width: 100%;
				width: 70%;

				img {
					max-height: 80vh;

				}
				
			}
		}

		.meta-wrap {
			max-width: 60%;

			.book-meta {
				text-align: center;

				h1 {
					color: map-get($text-color-map, "title");
				}

				h3 {
					color: map-get($text-color-map, "body-dark");					
				}
	
				p {
					color: map-get($text-color-map, "emphasis");
					font-size: 1.2rem;
					margin-bottom: 0;
				}		

				p:last-child {
					margin-bottom: $section-spacing;
				}

				.genre {
					text-transform: capitalize;
				}		
			}

			.one-line {
				color: map-get($text-color-map, "body-dark");
				margin-bottom: $section-spacing;
			}

			/* Grow to be full width on small screens */
			@media (max-width: 600px) {			
				max-width: 100%;
			}		
		}
	}

	.md-content {
		color: map-get($text-color-map, "body-dark");
		margin-bottom: $section-spacing;
	}

	.preview {
		color: map-get($text-color-map, "emphasis");
		margin-bottom: $section-spacing;
		
		div {
			margin-bottom: $section-spacing;
		}

		p.read-first-chapter {
			text-align: left;
			font-weight: 700;
		}
	}

	.buy-now {
		margin-bottom: $section-spacing;

		.amazon-button {
			width: 160px;
			height: 47px;
			background-image: url("/assets/images/buttons/amazon-button.png");
			background-repeat:no-repeat;
		}

		.amazon-button-plain {
			text-align: left;

			a {
				background-color: #FECD57;
				border: 1px solid #FBC43C;
				font-family: Arial;
				font-weight: bold;
				color: #333333;
			}
		}
	}
}


/****************************************************************
* Related Books
*****************************************************************/
/* Related books can show up on other pages (e.g. author page, series page, book page, etc.)*/
.related-books {
	$container-width: 20rem;
	$book-width: 20rem;
	$book-height: $book-width * 1.5; /* Aspect ratio of book covers */
	
	$book-spacing: 1rem;

	display: flex;
	flex-wrap: wrap;

	.book-cover {
		background-position: center top;
	}

	.book-info {

	}

	.related-book-detail {
		flex-grow: 0;
		padding: $book-spacing;
		margin: $book-spacing;
		border: 1px solid $divider-color;
		min-width: $container-width;

		div.feature-image {
			width: $book-width;
			height: $book-height;
			margin: 0.5rem auto;
			overflow: hidden;
			background-size: cover;
			background-position: center;
			margin-bottom: $book-spacing;			
		}

		p {
			text-align: center;
			max-width: $container-width;
		}
	}
	
	/* Grow related posts to be full width on small screens */
	@media (max-width: 600px) {			
		.related-book-detail {
			width: 100%
		}

		.related-book-detail p {
			max-width: 100%;
		}

		.related-book-detail div.feature-image {
			width: $book-width * 1.2;
			height: $book-height * 1.2;
		}
	}
}

/****************************************************************
* Current Projects Progress
*****************************************************************/

.current-projects, .md-content {
	.no-projects {
		text-align: center;
	}

	.project {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;

		border: 1px solid map-get($text-color-map, "subtle");
		border-radius: 5px;
		padding: 3rem;
		margin: 2rem;

		.meta {
			width: 50%;

			h3 {
				text-transform: uppercase;
				margin: 0;
			}

			p {
				color: map-get($text-color-map, "emphasis");
				text-transform:capitalize;
				margin: 0;
			}
		}

		.progress {
			width: 50%;
			$progress-bar-height: 1rem;

			.meter {
				border: 1px solid $primary-color;
				border-radius: $progress-bar-height;
				margin-bottom: 0.5rem;

				.meter-fill {
					height: $progress-bar-height;
					border-radius: $progress-bar-height;
					background-color: $primary-color;
				}
			}

			p {
				color: map-get($text-color-map, "emphasis");
				text-transform: capitalize;
				margin: 0;
			}
		}
	}
	
}

/****************************************************************
* Books Summary Page
*****************************************************************/
.all-books {
	.series-of-books {
		margin-bottom: $section-spacing;
	}

	.series-genre {
		color: map-get($text-color-map, "emphasis");
		text-transform: capitalize;
	}
}

/****************************************************************
* Series Page
*****************************************************************/
.series-page {
	.md-content {
		color: map-get($text-color-map, "body-dark");
	}

	.series-genre {
		color: map-get($text-color-map, "emphasis");
		text-transform:capitalize;
		text-align: center;
	}
}

/****************************************************************
* Beta Reading Request Page
*****************************************************************/
.beta-request {
	.feature-image {
		margin-bottom: $section-spacing;
	}

	h2.beta-reading-open {
		color: $primary-color;
		text-align: center;
		font-size: 3rem;
		text-transform: uppercase;
		margin-bottom: $section-spacing;
	}

	h2 {
		margin-top: $section-spacing;
	}

	.book-vitals {
		h3 {
			text-align: center;
			font-weight: 700;
			font-size: 2rem;
		}

		li span {
			text-transform: capitalize;
		}

		.subtle {
			color: map-get($text-color-map, "subtle");
			font-style: italic;
		}

		max-width: 60%;
		padding: $section-spacing 3rem;
		border-radius: 1rem;
		border: 1px solid $divider-color;
		background-color: #fbfafa;
		margin: 0 auto;
		margin-bottom: $section-spacing;

		@media (max-width: 600px) {
			max-width: 100%;
		}

		h3.highlights {
			margin-top: $section-spacing;
		}

		p.other-info {
			color: map-get($text-color-map, "subtle");
			font-style: italic;
			text-align: center;
			margin-top: $section-spacing;
		}
	}
}

/****************************************************************
* Contact Form
*****************************************************************/
.contact-form {
	h1, p {
		text-align: center;
	}

	p.button {
		margin: $section-spacing 0;
	}

	input[type=text], input[type=email], textarea {
		width: 100%;
		box-sizing: border-box; /* So input aren't wider than form */
		padding: 0.7em;
		display: block;
		font-family: arial;
		font-size: 1.5rem;
		margin: 2rem 0;				
		border: 1px solid map-get($text-color-map, "subtle");
		border-radius: 5px;
	}

	input[type=text]:focus, input[type=email]:focus, textarea:focus {
		border: 1px solid $primary-color;
	}

	input[type=submit] {		
		font-size: 1.7rem;
		color: white;
		text-align: center;
		text-decoration: none;
		background-color: $primary-color;
		border: none;
		border-radius: 0.7rem;
		display: block;
		margin: 0 auto;
		cursor: pointer;
		padding: 1.5rem 5rem;	
	}

	/* Honeypot technique, hide the fake field */
	#m66 {
		display: none;
	}

	.kcaptcha-div {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 0.6rem;

		img { 
			width: 160px; 
			height: 80px; 
			border: none; 
			margin: 0 2rem 1rem 1rem;
		}

		input { 
			width: 235px; 
		}
	}

	.kcaptcha-input-div { 
		width: 255px; 
	}

	.mail-sent-false {
		display: block;
		width: 100%;
		text-align: center;
		background-color: rgb(255, 70, 70);
		color: white;
		padding: 0.5rem 1rem;
		box-sizing: border-box; /* So not wider than form */
	}

	/* Placeholder colors */
	::-webkit-input-placeholder { /* WebKit, Blink, Edge */
		color:    #9b9b9b;
	}
	:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		color:    #9b9b9b;
		opacity:  1;
	}
	::-moz-placeholder { /* Mozilla Firefox 19+ */
		color:    #9b9b9b;
		opacity:  1;
	}
	:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color:    #9b9b9b;
	}
	::-ms-input-placeholder { /* Microsoft Edge */
		color:    #9b9b9b;
	}
}


/****************************************************************
* Spotfiy Playlists
*****************************************************************/
.spotify-playlist {
	padding: 2rem;
	margin-bottom: 1.5rem;
}

/****************************************************************
* Character Sheet
*****************************************************************/

.character-sheet {
	h2 {
		text-align: center;
	}

	h3 {
		margin: 2rem 0;
		font-weight: bold;
		text-align: center;
		font-size: 2.5rem;
	}

	.filter-red {
		filter: invert(8%) sepia(93%) saturate(7210%) hue-rotate(19deg) brightness(85%) contrast(104%);
	}

	.filter-gold {
		filter: invert(54%) sepia(66%) saturate(4654%) hue-rotate(35deg) brightness(99%) contrast(98%);
	}

	.filter-blue {
		filter: invert(11%) sepia(68%) saturate(3497%) hue-rotate(218deg) brightness(98%) contrast(92%);
	}

	.filter-purple {
		filter: invert(9%) sepia(49%) saturate(6073%) hue-rotate(278deg) brightness(112%) contrast(100%);
	}

	.filter-bronze {
		filter: invert(28%) sepia(91%) saturate(447%) hue-rotate(356deg) brightness(98%) contrast(92%);
	}

	.filter-green {
		filter: invert(31%) sepia(86%) saturate(898%) hue-rotate(86deg) brightness(95%) contrast(91%);
	}

	.filter-white {
		filter: invert(70%) sepia(4%) saturate(18%) hue-rotate(10deg) brightness(87%) contrast(78%);
	}

	.filter-orange {
		filter: invert(57%) sepia(54%) saturate(2288%) hue-rotate(9deg) brightness(93%) contrast(95%);
	}
}

/****************************************************************
* Materialize collapsible
*****************************************************************/
div.character-sheet {
	@import "components/collapsible"; // Edited
}